.UserReportCard {
  border-radius: 5px;
  padding: 5px;
}

.UserReportCard-selectable:hover,
.UserReportCard-selectable:focus,
.UserReportCard-selectable:focus-within {
  background-color: skyblue;
}

.UserReportCard-light .UserReportCard-light-control {
  opacity: 0;
}

.UserReportCard-light:focus-within .UserReportCard-light-control,
.UserReportCard-light:focus .UserReportCard-light-control,
.UserReportCard-light:hover .UserReportCard-light-control {
  opacity: 1;
}

.UserReportCard-saveBlock-controls {
  display: flex;
  align-items: center;
  gap: 15px;
}

.UserReportCard-selectable {
  cursor: pointer;
}

.UserReportCard-layout-default.UserReportCard-selected {
  background-color: aliceblue;
}

.UserReportCard-layout-item {
  background: white;
  border: 1px solid aliceblue;
}

.UserReportCard-layout-item.UserReportCard-selected {
  background-color: deepskyblue;
}

.UserReportCard-layout-default .UserReportCard-saveBlock {
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
}

.UserReportCard-layout-default .UserReportCard-name {
  font-size: x-large;
}
