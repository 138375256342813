:root {
  --black: #000;
  --white: #ffffff;
  --gray: #fffdfd;
  --primary: #cc4893;
  --primary-light: #f5dae9;
  --primary-dark: #3d162c;
  --bg-primary-gray: #d8d8d8;
  --primary-active: #b83981;
  --primary-disabled: #c9428e;
  --primary-border: rgb(204, 72, 147, 0.25);
  --primary-box-shadow: rgba(204, 72, 147, 0.5);
  --secondary: #6c757d;
  --secondary-light: #f8f9fa;
  --secondary-dark: #343a40;
  --secondary-active: #5a6268;
  --secondary-border: #5a6268;
  --bg-dark: #464a63;
  --danger: #f73d4f;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus {
  outline: var(--primary-border) !important;
}

a {
  color: var(--primary);
}

a.active,
a:focus,
a:hover {
  color: var(--primary-active);
}

a:disabled,
button:disabled,
.btn:disabled {
  cursor: not-allowed;
}

/* Buttons */
.btn {
  border-radius: 0;
  color: var(--primary);
  background-color: var(--white);
  border-color: var(--primary-border);
}

.btn:hover,
.btn:focus {
  color: var(--white);
  background-color: var(--primary-active);
  border-color: var(--primary-border);
}

.btn:active {
  color: var(--white) !important;
  background-color: var(--primary-active) !important;
  border-color: var(--primary-border) !important;
}

.btn:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-box-shadow);
}

.btn:disabled {
  background-color: var(--primary-disabled);
  border-color: var(--primary-border);
}

.btn-primary {
  color: var(--white);
  background-color: var(--primary);
}

.btn-light {
  color: var(--primary);
  background-color: var(--white);
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active {
  background-color: var(--primary);
}

.btn-light:disabled:hover,
.btn-light:disabled:focus,
.btn-light:disabled:active,
.btn-light:disabled {
  color: var(--black);
  background-color: var(--gray);
  border-color: var(--primary);
}

.btn-link {
  color: var(--primary);
  background: none;
  border: none;
}

.btn-link.inline-block {
  display: inline-block;
  padding: 0 0 0 0.5rem;
  text-decoration: underline;
}

.btn-link:hover,
.btn-link:focus {
  color: var(--primary);
  background: none;
  border: none;
}

.btn-link:active {
  color: var(--primary);
  background: none !important;
  border: none !important;
}

.btn-link:disabled {
  background: none;
  border: none;
}

.btn-secondary {
  color: var(--white);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  color: var(--white);
  background-color: var(--secondary-active);
  border-color: var(--secondary-border);
}

/* Forms */
legend {
  font-size: 1em;
}
.required:after {
  content: ' *';
  color: var(--primary);
}

.form-control:focus,
.form-control:active {
  box-shadow: 0 0 0 0.2rem var(--primary-box-shadow) !important;
  border-color: var(--primary-active) !important;
}

input.form-control.invalid {
  border-color: var(--primary) !important;
}

input.form-check-input[type='radio'] {
  margin-top: 0.5em;
  font: 12px/1em sans-serif;
}

input.form-check-input[type='radio']:focus {
  outline-color: var(--primary-active);
}

input.form-check-input[type='radio']:checked:before {
  display: inline-block;
  position: absolute;
  color: white;
  width: 1.38em;
  height: 1.38em;
  padding-top: 0.15em;
  font-size: 0.8em;
  background-color: var(--primary);
  border-radius: 50%;
  content: '✓';
  text-align: center;
}

.alert-warning {
  color: var(--primary-dark);
  background-color: var(--primary-light);
  border-color: var(--primary);
}

/* Helper classes */
.fluid {
  display: flex;
}

.fluid > * {
  margin-right: 1em;
}

.text-primary {
  color: var(--primary) !important;
}

.text-black {
  color: var(--black) !important;
}

.bg-dark {
  background-color: var(--bg-dark) !important;
}

.border-dotted-primary {
  border: 2px dotted var(--primary);
}

.hide---before::before {
  display: none;
}
.hide---after::after {
  display: none;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}

.input-date-shrinkable {
  position: relative;
  height: calc(1.5em + 0.75rem + 2px);
}

.input-date-shrinkable > input[type='date'] {
  position: absolute;
  inset: 0;
}
