.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.handsontable .wtHider {
  position: relative;
  height: fit-content !important;
}

/* by munib */

td.current.highlight div:first-child {
  height: 33px;
}

td.current.highlight .dropdown {
  position: absolute;
}

td.current.highlight .dropdown-menu.show {
  position: absolute !important;
  transform: none !important;
  top: 40px !important;
  left: -130px !important;
}

tbody tr:last-child td.current.highlight .dropdown-menu.show {
  top: -75px !important;
  left: -170px !important;
}

.customer-rfps-table table > tbody td:nth-child(9) > div,
.customer-rfps-table table > tbody td:nth-child(10) > div {
  height: 100%;
  width: 100%;
}

.customer-rfps-table table > tbody td:nth-child(9) > div > button,
.customer-rfps-table table > tbody td:nth-child(10) > div > button {
  height: inherit;
}

.customer-rfps-table table > tbody td:nth-child(9),
.customer-rfps-table table > tbody td:nth-child(10) {
  padding: 0;
}
