.TextButton {
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
}

button.TextButton-pseudo-disabled:disabled {
  cursor: text;
  color: darkgrey;
  border-bottom-color: darkgrey;
}

.TextButton-pseudo-enabled {
  color: steelblue;
  border-bottom: 1px dotted steelblue;
  cursor: pointer;
}

.TextButton-pseudo-enabled:hover,
.TextButton-pseudo-enabled:focus {
  color: cornflowerblue;
  border-bottom: 1px dotted cornflowerblue;
}

.TextButton-gentle:not(:hover):not(:focus) {
  border-bottom-color: transparent;
}
