.FieldAssignmentListTable td,
.FieldAssignmentListTable th {
  padding: 24px 4px;
  border-bottom: 6px solid lightgrey;
  text-align: center;
  position: relative;
}

.FieldAssignmentListTable > tbody > tr.expand > td {
  border-bottom: 0 none;
}

.FieldAssignmentListTable .source {
  background-color: lightgreen;
}

.FieldAssignmentListTable .mapping {
  background-color: lightblue;
}

.FieldAssignmentListTable .no-access {
  background-color: lightgrey;
}
.FieldAssignmentListTable .read-only {
  background-color: lightyellow;
}
.FieldAssignmentListTable .read-write-mapped {
  background-color: lightblue;
}
.FieldAssignmentListTable .read-write {
  background-color: lightgreen;
}

.FieldAssignmentListTable .turnOff td {
  filter: grayscale(100%);
  opacity: 0.6;
}

.FieldAssignmentListTable ._isRemoved td {
  filter: grayscale(100%);
  opacity: 0.4;
}

.FieldAssignmentListTable ._isRemoved td:after {
  content: ' ';
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: black;
}

.FieldAssignmentListTable .dirty {
  outline: 1px solid goldenrod;
  box-shadow: 0px 0px 7px 2px rgba(218, 165, 32, 0.41);
}

.FieldAssignmentListTable .JSONEditor-prop-inherited {
  opacity: 0.4;
}
.FieldAssignmentListTable
  .JSONEditor-prop-inherited
  > .jse-contents-outer
  > .jse-contents
  > .jse-identifier
  > .jse-key {
  font-style: italic;
  background: lightgrey;
}
.FieldAssignmentListTable .JSONEditor-prop-inherited-text {
  opacity: 0.4;
  font-style: italic;
  background: lightgrey;
}

.FieldAssignmentListTable .JSONEditor-prop-template {
  opacity: 0.4;
}

.FieldAssignmentListTable .JSONEditor-prop-custom {
  opacity: 1;
}
.FieldAssignmentListTable
  .JSONEditor-prop-custom
  > .jse-contents-outer
  > .jse-contents
  > .jse-identifier
  > .jse-key {
  background: lightblue;
}
.FieldAssignmentListTable .JSONEditor-prop-custom-text {
  background: lightblue;
}
